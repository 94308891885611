import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePasswordToggle } from "../../hooks/usePasswordToggle";
import Logo from "../../assets/images/logos/canadoctors/canadoctorsColors.svg";
import { Link } from "react-router-dom";
import FormField from "../../components/FormFiel/FormFiel";
import {
  EMAIL,
  PASSWORD,
  PHONE,
  LASTNAME,
  NAME,
} from "../../validations/SignUP";
import { getUsersEmails } from "../../services/getUsersEmails";
import { useEffect, useState } from "react";

type UserSubmitForm = {
  firstName: string;
  lastName: string;
  country: string;
  countryCode: string;
  email: string;
  password: string;
  phone: string;
  confirmPassword: string;
  termConditions: string;
  reference: number;
  active: boolean;
  doctor: boolean;
  medicalRecordComplete: boolean;
  datos: any;
};

function SignUp() {
  const [passwordInputType, toggleIcon] = usePasswordToggle();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Estado de submit
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserSubmitForm>();

  const email = watch("email");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  useEffect(() => {
    setEmailError(null); // Limpiar el error cuando el email cambia
  }, [email]); // Asegurarse de que esto esté ligado al email

  useEffect(() => {
    setPasswordError(null); // Limpiar el error de contraseñas al cambiar alguna de las contraseñas
  }, [password, confirmPassword]);

  const onSubmit: SubmitHandler<UserSubmitForm> = async (data) => {
    setIsSubmitting(true); // Habilitar el estado de envío
    try {
      // Check if email is already taken
      const emailExists = await getUsersEmails(data.email);
      if (emailExists) {
        setEmailError("El correo ya está registrado.");
        setIsSubmitting(false); // Deshabilitar submit si hay error
        return; // Detener el envío si el correo está registrado
      }

      // Check if passwords match
      if (data.password !== data.confirmPassword) {
        setPasswordError("Las contraseñas no coinciden.");
        setIsSubmitting(false); // Deshabilitar submit si las contraseñas no coinciden
        return; // Detener el envío si las contraseñas no coinciden
      }

      // Continue with form submission if email is valid
      const datos = {
        firstName: data.firstName,
        lastName: data.lastName,
        country: data.country,
        email: data.email,
        phone: data.countryCode + data.phone,
        password: data.password,
        active: true,
        doctor: false,
        medicalRecordComplete: false,
        reference: 1,
      };

      await fetch("https://canadoctors.herokuapp.com/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: datos }),
      });

      navigate("/"); // Redirect to home page after successful registration
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
      setIsSubmitting(false); // Deshabilitar submit al finalizar la operación
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#00A099]/80 to-[#8CBB93]/90 bg-opacity-40 min-h-screen flex justify-center items-center">
      <div className="absolute w-60 h-60 rounded-xl bg-white -top-5 -left-16 z-0 transform rotate-45 hidden md:block"></div>
      <div className="absolute w-48 h-48 rounded-xl bg-white bottom-6 right-6 transform rotate-12 hidden md:block"></div>
      <div className="py-12 px-14 m-4 bg-white rounded-2xl shadow-xl max-w-3xl w-full z-10">
        <div className="pb-6 w-full flex justify-center">
          <img src={Logo} alt="logo" width={300} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <div className="gap-4 sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
              <div className="xl:w-1/2">
                <FormField
                  id="firstName"
                  name="nombre"
                  required={true}
                  type="text"
                  maxLength={25}
                  minLength={3}
                  placeholder="Nombre"
                  errors={errors}
                  register={register}
                  validation={NAME}
                />
              </div>

              <div className="xl:w-1/2">
                <FormField
                  id="lastName"
                  name="apellido"
                  required={true}
                  type="text"
                  maxLength={20}
                  minLength={3}
                  placeholder="Apellido"
                  errors={errors}
                  register={register}
                  validation={LASTNAME}
                />
              </div>
            </div>

            <FormField
              id="country"
              name="país"
              required={true}
              type="select"
              errors={errors}
              register={register}
              options={[
                { value: "", label: "Seleccionar País" },
                { value: "AR", label: "🇦🇷 Argentina" },
                { value: "PR", label: "🇵🇷 Puerto Rico" },
              ]}
            />

            <div className="sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
              <div className="flex-grow xl:w-1/4 xl:pr-2">
                <FormField
                  id="countryCode"
                  name="codigo país"
                  required={true}
                  type="select"
                  errors={errors}
                  register={register}
                  options={[
                    { value: "", label: "Código País" },
                    { value: "+54", label: "🇦🇷 Argentina +54" },
                    { value: "+1", label: "🇵🇷 Puerto Rico +1" },
                  ]}
                />
              </div>

              <div className="flex-grow">
                <FormField
                  id="phone"
                  name="telefono"
                  required={true}
                  type="number"
                  maxLength={10}
                  minLength={8}
                  placeholder="Teléfono"
                  errors={errors}
                  register={register}
                  validation={PHONE}
                />
              </div>
            </div>

            <FormField
              id="email"
              required={true}
              type="email"
              maxLength={35}
              minLength={6}
              placeholder="Correo Electrónico"
              errors={errors}
              validation={EMAIL}
              register={register}
              name="email"
            />

            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}

            <div className="gap-4 sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
              <div className="flex flex-col relative xl:w-1/2">
                <FormField
                  id="password"
                  name="contraseña"
                  type={passwordInputType as any}
                  required={true}
                  minLength={8}
                  maxLength={16}
                  placeholder="Contraseña"
                  errors={errors}
                  register={register}
                  validation={PASSWORD}
                  toggleIcon={toggleIcon}
                />
              </div>

              <div className="flex flex-col relative xl:w-1/2">
                <FormField
                  id="confirmPassword"
                  type={passwordInputType as any}
                  minLength={8}
                  required={true}
                  maxLength={16}
                  placeholder="Confirmar Contraseña"
                  errors={errors}
                  register={register}
                  validation={PASSWORD}
                  toggleIcon={toggleIcon}
                  name="confirmar contraseña"
                />
                {passwordError && (
                  <p className="text-red-500 text-sm">{passwordError}</p>
                )}
              </div>
            </div>
            <div className="w-full">
              <p className="text-gray-400 text-sm mt-2">
                * La contraseña debe tener al menos 8 caracteres, una mayúscula,
                una minúscula y un número.
              </p>
            </div>

            <FormField
              id="termConditions"
              label="He leído y comprendido los Términos y Condiciones."
              name="terminos y condiciones"
              type="checkbox"
              required={true}
              errors={errors}
              errorCheckbox="Debes aceptar los términos y condiciones"
              register={register}
              toLink="/terms"
            />

            <div className="space-y-6">
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting} // Deshabilitar el botón si está enviando
                  className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3  rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500"
                >
                  {isSubmitting ? (
                    <svg
                      role="status"
                      className="inline h-8 w-8 animate-spin mr-2 text-gray-200 dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    "Registrarme"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="text-center mt-6">
          <p className="mt-4 text-sm">
            ¿Ya tienes una cuenta?{" "}
            <Link to="/" className="text-sm text-[#5FBAA8] hover:opacity-75">
              Iniciar Sesión
            </Link>
          </p>
        </div>
      </div>
      <div className="w-40 h-40 absolute bg-white rounded-full top-2 right-2 hidden md:block"></div>
      <div className="w-20 h-40 absolute bg-white rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
    </div>
  );
}

export default SignUp;
