const baseURL = "https://canadoctors.herokuapp.com/users/email";

const getUsersEmails = async (email) => {
  try {
    const response = await fetch(`${baseURL}/${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
    });

    if (!response.ok) {
      console.error("Error al obtener usuario:", response.statusText);
      return undefined; // Si hay un error, asumimos que el correo no está registrado
    }

    const userData = await response.json();

    // Verificar si el usuario existe
    if (!userData || !userData.id) {
      return undefined; // Correo no registrado
    }

    return userData; // Correo registrado
  } catch (error) {
    console.error("Error en la solicitud:", error);
    return undefined; // En caso de error, asumimos que el correo no está registrado
  }
};

export { getUsersEmails };
