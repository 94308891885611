import React from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../../contexts/authContext";

//puerto rico
import PersonalInformationPr from "../puertoRico/PersonalInformationPr";
import PersonalCharacteristicsPr from "../puertoRico/PersonalCharacteristicsPr";
import MedicalInformationPr from "../puertoRico/MedicalInformationPr";
import MedicalConditionPr from "../puertoRico/MedicalConditionPr";

//argentina
import PersonalInformationAr from "../argentina/PersonalInformationAr";
import PersonalCharacteristicsAr from "../argentina/PersonalCharacteristicsAr";
import MedicalInformationAr from "../argentina/MedicalInformationAr";
import MedicalConditionAr from "../argentina/MedicalConditionAr";

const STEPS_AMOUNT = 3;

const FinishSectionButton: React.FC<{
  onClick: () => void;
  isDisabled: boolean;
  children: React.ReactNode;
}> = ({ onClick, isDisabled, children }) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      type="button"
      className="mt-6 bg-cdgreen text-white rounded py-6 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
    >
      {children}
    </button>
  );
};

function MedicalRecord() {
  const { user, isAuthenticated } = useAuthContext();
  const [formStep, setFormStep] = React.useState(0);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  function handleStepCompletion() {
    setFormStep((cur) => cur + 1);
  }

  function handleGoBackToPreviousStep() {
    setFormStep((cur) => cur - 1);
  }

  function onSubmit(values: any): void {
    handleStepCompletion();
    let datos = {
      ...values,
      patientId: user.id,
    };

    const urlPr = "https://canadoctors.herokuapp.com/medics/create";
    const urlAr = "https://canadoctors.herokuapp.com/medics/create/ar";

    fetch(user.country === "AR" ? urlAr : urlPr, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isAuthenticated}`,
      },
      body: JSON.stringify({ data: datos }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    fetch(`https://canadoctors.herokuapp.com/users/${user.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isAuthenticated}`,
      },
      body: JSON.stringify({
        data: { medicalRecordComplete: true },
      }),
    });
  }

  return (
    <div className="w-full h-full rounded-lg shadow-2xl mx-auto overflow-hidden">
      <div className="h-2 bg-gray-200 w-full overflow-hidden">
        <div
          style={{ width: `${(formStep / STEPS_AMOUNT) * 100}%` }}
          className="h-full bg-cdgreen transform duration-300 ease-out"
        ></div>
      </div>
      <div className="px-16 py-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep < STEPS_AMOUNT && (
            <div className="flex items-center font-medium mb-8">
              {formStep > 0 && (
                <button
                  onClick={handleGoBackToPreviousStep}
                  type="button"
                  className="focus:outline-none"
                >
                  <svg
                    viewBox="0 0 20 20"
                    version="1.1"
                    className="w-6 mr-2 fill-current text-gray-300 hover:text-gray-400 focus:outline-none"
                  >
                    <polygon
                      id="Combined-Shape"
                      points="10 13 18 13 18 7 10 7 10 2 2 10 10 18 10 13"
                    ></polygon>
                  </svg>
                </button>
              )}
              Etapa {formStep + 1} de {STEPS_AMOUNT}
            </div>
          )}
          {formStep >= 0 && (
            <section className={`${formStep === 0 ? "block" : "hidden"}`}>
              <h2 className="font-semibold text-3xl mb-4">
                Información Personal
              </h2>

              {user.country === "PR" ? (
                <PersonalInformationPr register={register} errors={errors} />
              ) : (
                <PersonalInformationAr register={register} errors={errors} />
              )}

              <FinishSectionButton
                onClick={handleStepCompletion}
                isDisabled={!isValid}
              >
                Siguiente
              </FinishSectionButton>
            </section>
          )}
          {formStep >= 1 && (
            <section className={`${formStep === 1 ? "block" : "hidden"}`}>
              <h2 className="font-semibold text-3xl mb-4">
                Características Personales
              </h2>

              {user.country === "PR" ? (
                <PersonalCharacteristicsPr
                  register={register}
                  errors={errors}
                />
              ) : (
                <PersonalCharacteristicsAr
                  register={register}
                  errors={errors}
                />
              )}

              <FinishSectionButton
                onClick={handleStepCompletion}
                isDisabled={!isValid}
              >
                Siguiente
              </FinishSectionButton>
            </section>
          )}
          {formStep >= 2 && (
            <section className={`${formStep === 2 ? "block" : "hidden"}`}>
              <h2 className="font-semibold text-3xl mb-4">
                Información Médica
              </h2>

              {user.country === "PR" ? (
                <MedicalInformationPr
                  register={register}
                  errors={errors}
                  watch={watch}
                />
              ) : (
                <MedicalInformationAr
                  register={register}
                  errors={errors}
                  watch={watch}
                />
              )}

              <FinishSectionButton
                onClick={handleStepCompletion}
                isDisabled={!isValid}
              >
                Siguiente
              </FinishSectionButton>
            </section>
          )}
          {formStep >= 3 && (
            <section className={`${formStep === 3 ? "block" : "hidden"}`}>
              <h2 className="font-semibold text-3xl mb-4">Condicion Medica</h2>

              {user.country === "PR" ? (
                <MedicalConditionPr register={register} errors={errors} />
              ) : (
                <MedicalConditionAr register={register} errors={errors} />
              )}

              <button
                disabled={!isValid}
                type="submit"
                className="mt-6 bg-cdgreen text-white rounded px-8 py-6 w-full disabled:bg-gray-400"
              >
                Enviar Formulario
              </button>
            </section>
          )}
          {formStep === 4 && (
            <section className="text-center">
              <h2 className="font-semibold text-3xl mb-4">
                ¡¡Historial médico completado!!
              </h2>

              <div className="h-full pt-6">
                <button
                  onClick={() => window.location.reload()}
                  className="py-4 px-5 bg-cdgreen hover:opacity-75 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg"
                  type="button"
                >
                  Volver al Dashboard
                </button>
              </div>
            </section>
          )}
          {/*
           {user.country === "puerto rico" ? <ChekcoutP /> : <CheckoutAr />}
          <p>{isValid ? "Valid" : "Invalid"}</p>
            <pre className="text-sm text-gray-700">
              {JSON.stringify(watch(), null, 2)}
            </pre>*/}
        </form>
      </div>
    </div>
  );
}

export default MedicalRecord;
