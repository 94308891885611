const baseURL = "https://canadoctors.herokuapp.com/users/email";
const baseURLMedical = "https://canadoctors.herokuapp.com/medics";

const getPatientMedical = async (email) => {
  try {
    // Obtener información del usuario
    const response = await fetch(`${baseURL}/${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
    });

    if (!response.ok) {
      console.error("Error al obtener usuario:", response.statusText);
      return null;
    }

    const userData = await response.json();

    // Verificar si el usuario existe
    if (!userData || !userData.id) {
      console.error("Usuario no encontrado");
      return null;
    }

    // Obtener información médica del usuario
    const responseMedical = await fetch(`${baseURLMedical}/${userData.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
    });

    if (!responseMedical.ok) {
      console.error(
        "Error al obtener información médica:",
        responseMedical.statusText
      );
      return null;
    }

    const dataMedical = await responseMedical.json();

    // Validar si existen los datos médicos
    if (!dataMedical || !dataMedical.patientId || !dataMedical.socialSecurity) {
      console.error("Información médica no encontrada");
      return null;
    }

    return {
      patientId: dataMedical.patientId,
      socialSecurity: dataMedical.socialSecurity,
    };
  } catch (error) {
    console.error("Error al obtener usuario y registro médico:", error);
    throw error; // Puedes manejar el error según tus necesidades
  }
};

export { getPatientMedical };
