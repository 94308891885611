import FormField from "../../../components/FormFiel";
import { getPatientMedical } from "../../../services/getPatientMedical";
import { getPatientsId } from "../../../services/getPatients";
import { EMAIL } from "../../../validations/SignUP";

type Patient = {
  id: any;
  socialSecurity: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type PropsForm = {
  register: any;
  errors: any;
  watch?: any;
  emailExists: boolean;
  onEmailExistence: (patient: Patient | null) => void;
  setLoading: (loading: boolean) => void;
};

function EmailValidation({
  register,
  errors,
  watch,
  emailExists,
  onEmailExistence,
  setLoading,
}: PropsForm) {
  const checkEmailExist = async (email: string) => {
    try {
      const res = await getPatientMedical(email);
      const adaptedPatient: Patient | null = res
        ? {
            id: res.patientId,
            socialSecurity: res.socialSecurity,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          }
        : null;

      if (adaptedPatient) {
        const patientDetails = await getPatientsId(adaptedPatient.id);
        adaptedPatient.firstName = patientDetails.firstName;
        adaptedPatient.lastName = patientDetails.lastName;
        adaptedPatient.email = patientDetails.email;
        adaptedPatient.phone = patientDetails.phone;
      }

      onEmailExistence(adaptedPatient);
    } catch (error) {
      console.error(
        "Error al verificar la existencia del correo electrónico:",
        error
      );
      // Puedes mostrar un mensaje de error o tomar otras acciones necesarias
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    if (newEmail.length >= 6) {
      setLoading(true); // Inicia la carga
      await checkEmailExist(newEmail);
      setLoading(false); // Detiene la carga
    }
  };

  return (
    <div className="space-y-2">
      <FormField
        id="email"
        required={true}
        type="email"
        maxLength={35}
        minLength={6}
        placeholder="Correo Electrónico"
        errors={errors}
        register={register}
        name="email"
        validation={EMAIL}
        onChange={handleEmailChange}
      />

      {emailExists && (
        <p className="text-red-500 text-sm mt-2">
          El correo electrónico ya existe en la base de datos.
        </p>
      )}
    </div>
  );
}

export default EmailValidation;
