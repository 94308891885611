const baseURL = "https://canadoctors.herokuapp.com/transactions";
const baseURLUser = "https://canadoctors.herokuapp.com/users";

const fetchWithAuth = async (url) => {
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error(`Error en fetch: ${url}`, error);
    return null;
  }
};

// Obtener todas las transacciones con datos del usuario
const getTransactions = async () => {
  const [transactionData, userData] = await Promise.all([
    fetchWithAuth(baseURL),
    fetchWithAuth(baseURLUser),
  ]);

  if (!transactionData || !userData) return [];

  const userMap = new Map(userData.map((user) => [user.id, user]));

  return transactionData.map((transaction) => {
    const user = userMap.get(transaction.patientId) || {};
    return {
      ...transaction,
      name: user.firstName || "Desconocido",
      lastName: user.lastName || "Desconocido",
      email: user.email || "N/A",
      phone: user.phone || "N/A",
      country: user.country || "N/A",
      urlProfileImg: user.urlProfileImg || "",
      reference: user.reference || "",
    };
  });
};

// Obtener transacciones filtradas por referencia
const getTransactionsReference = async (reference) => {
  const [transactionData, userData] = await Promise.all([
    fetchWithAuth(baseURL),
    fetchWithAuth(baseURLUser),
  ]);

  if (!transactionData || !userData) return [];

  const patientsByReference = new Map(
    userData
      .filter((user) => user.rol === 1 && user.reference === reference)
      .map((user) => [user.id, user])
  );

  return transactionData
    .filter((transaction) => patientsByReference.has(transaction.patientId))
    .map((transaction) => {
      const user = patientsByReference.get(transaction.patientId) || {};
      return {
        ...transaction,
        name: user.firstName || "Desconocido",
        lastName: user.lastName || "Desconocido",
        email: user.email || "N/A",
        phone: user.phone || "N/A",
        country: user.country || "N/A",
        urlProfileImg: user.urlProfileImg || "",
      };
    });
};

// Agrupar transacciones por mes y referencia
const getTransactionsByMonth = async (year, month, reference) => {
  const transactions = reference
    ? await getTransactionsReference(reference)
    : await getTransactions();

  if (!transactions.length) return null;

  const formattedMonth = month.toString().padStart(2, "0");

  const transactionsForMonth = transactions.filter((transaction) => {
    const date = new Date(transaction.date);
    return (
      date.getFullYear() === year &&
      (date.getMonth() + 1).toString().padStart(2, "0") === formattedMonth
    );
  });

  return transactionsForMonth.reduce(
    (acc, transaction) => {
      const { type, method, amount } = transaction;

      // Contar tipos de transacción
      acc.transactionTypeCount[type] =
        (acc.transactionTypeCount[type] || 0) + 1;

      // Contar métodos de pago
      if (!acc.paymentMethodCount[method]) {
        acc.paymentMethodCount[method] = { count: 0, amounts: {} };
      }
      acc.paymentMethodCount[method].count++;
      acc.paymentMethodCount[method].amounts[amount] =
        (acc.paymentMethodCount[method].amounts[amount] || 0) + 1;

      return acc;
    },
    { transactionTypeCount: {}, paymentMethodCount: {} }
  );
};

export { getTransactions, getTransactionsReference, getTransactionsByMonth };
