import { useAuthContext } from "../../contexts/authContext";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

type ChekcoutPProps = {
  typeLicence: string;
  price: any;
};

function ChekcoutP({ typeLicence, price }: ChekcoutPProps) {
  const { user } = useAuthContext();

  const initialOptions = {
    "client-id":
      //PRUEBA "AcuPvAhjpm-WJfsBaZMDnjJN497XemM7Mf6TUlFKP4fOaQnXI1-5-8jGJtGe7Kgen_sTXFEjlzl69Kt0",
      "AaeeuL5jSwHKG7x3Fq__6u_pXeZkdouxTOdCRcQe2VJb8JlwCZrYKbquOlQQAi0mLG4LvxrUT2JulEP_",

    locale: "en_US",
  };

  return (
    <div className="mt-4 relative flex-1 flex-col justify-center">
      <div className="flex flex-col justify-center items-center">
        <div className="bg-gray-100 flex flex-col justify-center items-center text-black shadow-lg shadow-gray-300 w-full h-auto p-4 rounded-2xl relative mx-12">
          <p className="text-black font-semibold capitalize text-lg">
            Video consulta médica
          </p>
          <p className="text-black text-md mt-5">
            Acceso a plataforma Canadoctors, consulta con especialista médico en
            endocannabinología.
          </p>
          <p className="text-black text-lg mt-5 font-bold">{typeLicence}</p>

          <span className="text-lg font-extrabold mt-5">
            Total: ${price}.00
          </span>
        </div>
      </div>
      <div className="mt-8 w-full text-center">
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: price,
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              return actions.order?.capture().then((details) => {
                let datos = {
                  type: typeLicence,
                  amount: parseFloat(details.purchase_units[0].amount.value),
                  date: details.create_time,
                  method: "PayPal",
                  orderId: details.id,
                  state: details.status,
                  patientId: user.id,
                };

                fetch("https://canadoctors.herokuapp.com/transactions", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "token"
                    )}`,
                  },
                  body: JSON.stringify({ data: datos }),
                }).then((response) => response.json());
                try {
                } catch (error) {
                  console.log(error);
                }
                const name = details.payer.name?.given_name;
                alert(`Transaction completed by ${name}`);
              });
            }}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
}

export default ChekcoutP;
