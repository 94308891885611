import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AuthContextProvider } from "./contexts/authContext";
import Home from "./container/Home/Home";
import SignIn from "./container/SignIn/SignIn";
import SignInPlanteo from "./container/SignInPlanteo";
import SignUp from "./container/SingUp/SignUp";
import SignUpPlanteo from "./container/SignUpPlanteo/SignUpPlanteo";
import SignUpStrain from "./container/SingUpStrain/SignUpStrain";
import PublicRoute from "./components/router/PublicRoute";
import PrivateRoute from "./components/router/PrivateRoute";
import Profile from "./container/Profile/Profile";
import TermsandConditions from "./container/TermsAndCondition/TermAndCondition";
import TransactionHistory from "./container/TransactionHistory";
import Services from "./container/Services";
import SignUpSabia from "./container/SignUpSabia";
import SignUpFM from "./container/SignUpFM";
import SignUpPr from "./container/SignUpPr";
import PatientsCards from "./container/CardContainer/PatientsCards";
import PatientsTable from "./container/Patients/PatientsTable";
import PatientsView from "./container/Patients/PatientsView";
import PatientsManagementTable from "./container/Patients/PatientsManagementTable";
import DispensaryCards from "./container/CardContainer/DispensaryCards";
import TagManager from "react-gtm-module";
import SignUpTetra from "./container/SignUpTetra";
import SignUpStrainSimple from "./container/SignUpStrainSimple";
import TakeCareOfYourself from "./container/TakeCareOfYourself";
import Status from "./container/Status";
import TakeCareOfYourselfStrain from "./container/TakeCareOfYourselfStrain";
import FinishPayment from "./container/FinishPayment";
import PatientsPriority from "./container/Patients/PatientsPriority";
import TakeCareOfYourselfPlanteta420 from "./container/TakeCareOfYourselfPlanteta420";
import Panama from "./container/Panama";
import TakeCareOfYourselfCanadoctors from "./container/TakeCareOfYourselfCanadoctors";
import TakeCareOfYourselfWHeals from "./container/TakeCareOfYourselfWHeals";

const tagManagerArgs = {
  gtmId: "GTM-WGQVP68",
  dataLayer: {
    event: "VirtualPageview",
    page: {
      title: "titulo",
    },
  },
};

TagManager.initialize(tagManagerArgs);

/*ROLES
1-PACIENTE
2-DOCTOR
3-DISPENSARIO
4-ADMINISTRADOR
5-SUPERADMIN
*/

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/terms" element={<TermsandConditions />} />
            {/* <Route path="/panama" element={<Panama />} />*/}
            <Route path="/cuidaDeTi" element={<TakeCareOfYourself />} />
            <Route
              path="/signup/canadoctors"
              element={<TakeCareOfYourselfCanadoctors />}
            />
            <Route
              path="/thestrainmarket"
              element={<TakeCareOfYourselfStrain />}
            />
            
            <Route
              path="/planeta420"
              element={<TakeCareOfYourselfPlanteta420 />}
            />
            <Route path="/wheals" element={<TakeCareOfYourselfWHeals />} />
            <Route
              path="/signup/strain/:firstName/:lastName/:email/:phone"
              element={<SignUpStrain />}
            />

            
            <Route path="/signup/planteo" element={<SignUpPlanteo />} />
            <Route path="/signup/Pr" element={<SignUpPr />} />
            <Route path="/signup/sabia" element={<SignUpSabia />} />
            <Route path="/signup/FirstMedical" element={<SignUpFM />} />
            <Route path="/signup/Strain" element={<SignUpStrainSimple />} />
            <Route path="/signin/planteo" element={<SignInPlanteo />} />

            <Route path="/estado" element={<Status />} />
            <Route path="/finalizarPago" element={<FinishPayment />} />
          </Route>

          <Route
            path="/paciente/*"
            element={<PrivateRoute allowedRoles={[1]} />}
          >
            <Route index element={<Home />} />
            <Route path="perfil" element={<Profile />} />
            <Route path="misPagos" element={<TransactionHistory />} />
            <Route path="servicios" element={<Services />} />
          </Route>

          <Route path="/doctor/*" element={<PrivateRoute allowedRoles={[2]} />}>
            <Route index element={<Home />} />
            <Route path="perfil" element={<Profile />} />
          </Route>

          <Route
            path="/dispensario/*"
            element={<PrivateRoute allowedRoles={[3]} />}
          >
            <Route index element={<Home />} />
            <Route path="pacientes" element={<PatientsCards />} />
            <Route path="pacientes/listado" element={<PatientsTable />} />
            <Route path="pacientes/listado/:id" element={<PatientsView />} />
            <Route
              path="pacientes/gestiones"
              element={<PatientsManagementTable />}
            />
            <Route path="pacientes/gestiones/:id" element={<PatientsView />} />
          </Route>

          <Route
            path="/administrador/*"
            element={<PrivateRoute allowedRoles={[4]} />}
          >
            <Route index element={<Home />} />
            <Route path="pacientes" element={<PatientsCards />} />
            <Route path="pacientes/listado" element={<PatientsTable />} />
            <Route
              path="pacientes/gestiones"
              element={<PatientsManagementTable />}
            />
            <Route path="pacientes/listado/:id" element={<PatientsView />} />
            <Route path="pacientes/prioridad" element={<PatientsPriority />} />
            <Route path="pacientes/gestiones/:id" element={<PatientsView />} />
          </Route>

          <Route
            path="/superadmin/*"
            element={<PrivateRoute allowedRoles={[5]} />}
          >
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
